import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { RETURN_CREATION_DIALOG } from "@/constants/dialog-constants";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "@/store/reducers/dialogReducer";
import DialogLoaderButton from "../loader-button/DialogLoaderButton";
import { RootState } from "@/store/store";
import { Seller } from "@/interfaces/Seller";
import SellerSelect from "../supplier-select/SellerSelect";
import { useNavigate } from "react-router-dom";
import { setReturnHeader } from "@/store/reducers/returnHeaderReducer";
import { NavigationRoutes } from "../navigation/constants";
import { Switch } from "../ui/switch";
import { ReturnRequest, ReturnRequest_ } from "@/interfaces/Return";
import RequestSelect from "../request-select/RequestSelect";
import { HUB } from "@/interfaces/HUB";
import DropOffSelect from "../dropoff-select/DropOffSelect";
import { ScrollArea } from "../ui/scroll-area";
import Product from "../product/Product";
import {
  createTourneeViaRequest_NonVendable,
  createTourneeViaRequest_Vendable,
} from "@/services/return-creation-via-request.service";
import { handleFileDownload } from "@/utils/file-download";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import { toast } from "@/hooks/use-toast";
import { Tabs, TabsList, TabsTrigger } from "../ui/tabs";
import ReturnCodeInput from "../common/ReturnCodeInput";
import RequestList from "../request-select/RequestList";

const ReturnCreationDialog: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [returnCode, setReturnCode] = useState<number>(0);
  const [selectedSeller, setSelectedSeller] = useState<Seller>({} as Seller);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRequests, setSelectedRequests] = useState<ReturnRequest[]>(
    [] as ReturnRequest[]
  );
  const [selectedDropOff, setSelectedDropOff] = useState<HUB>({} as HUB);
  const isRefreshing = useSelector(
    (state: RootState) => state.refresh.isRefreshing
  );
  const [isRequestMode, setIsRequestMode] = useState(false);
  const [showSecondSection, setShowSecondSection] = useState(false);
  const [dialogWidth, setDialogWidth] = useState("sm:max-w-[400px]");
  const [selectedRequest, setSelectedRequest] = useState<ReturnRequest>(
    {} as ReturnRequest
  );
  const [, setMainSectionHeight] = useState(0);
  const mainSectionRef = useRef<HTMLDivElement>(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [modeStockVendable, setModeStockVendable] = useState(false);
  /*   const [sellableProducts, setSellableProducts] = useState<Article__[]>(
    [] as Article__[]
  ); */

  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialog
  );
  const isOpened = isOpen && dialogType === RETURN_CREATION_DIALOG;

  const handleCreateReturn = async () => {
    const requests: ReturnRequest_[] = selectedRequests.map((request) => {
      return {
        sellerId: selectedSeller.sellerId,
        headerid: request.num,
      };
    });
    setIsLoading(true);
    let response;
    if (!modeStockVendable) {
      response = await createTourneeViaRequest_NonVendable({
        numTournee: returnCode.toString(),
        sellerId: selectedSeller.sellerId,
        isSellerVIP: selectedSeller.isSellerVIP,
        entityId: selectedDropOff.id,
        demandes: requests,
      });
    } else {
      /*       const mappedRequests = requests.map((request) => ({
        headerId: request.headerid, // assuming the property is 'headerid' in lowercase
      }));
      const mappedProducts = sellableProducts.map((product) => ({
        productId: product.productId,
        qteEntrepote: product.quantity ?? 0,
      })); */
      response = await createTourneeViaRequest_Vendable({
        numTournee: returnCode.toString(),
        sellerId: selectedSeller.sellerId,
        isSellerVIP: selectedSeller.isSellerVIP,
        entityId: selectedDropOff.id,
        demandes: requests,
      });
    }
    if (response.status === 200 && response.data.success === true) {
      response.data.data !== "" && handleFileDownload(response.data.data, "");
      setIsLoading(false);
      dispatch(setRefreshing(!isRefreshing));
      toast({
        title: "Tournée créée avec succès",
        variant: "success",
      });
      handleClose();
    } else {
      setIsLoading(false);
      toast({
        title: "Erreur lors de la création de la tournée",
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  const handleRemoveRequest = (request: ReturnRequest) => {
    setSelectedRequests((prev) => prev.filter((r) => r.num !== request.num));
    setSelectedRequest({} as ReturnRequest);
    setShowSecondSection(false);
  };

  const handleClose = () => {
    dispatch(closeDialog());
    setSelectedDropOff({} as HUB);
    setSelectedSeller({} as Seller);
    setReturnCode(0);
    setSelectedRequests([] as ReturnRequest[]);
    setSelectedRequest({} as ReturnRequest);
    setShowSecondSection(false);
    setIsRequestMode(false);
  };

  const handleCancel = () => {
    if (isConfirmed) {
      setIsConfirmed(false);
    } else {
      handleClose();
    }
  };

  const handleSwitchChange = () => {
    setIsRequestMode(!isRequestMode);
    setSelectedRequests([] as ReturnRequest[]);
    setSelectedRequest({} as ReturnRequest);
    setShowSecondSection(false);
    setSelectedSeller({} as Seller);
  };

  const handleShowDetails = (request: ReturnRequest) => {
    setSelectedRequest(request);
    if (request.num === selectedRequest.num) {
      setShowSecondSection(!showSecondSection);
    } else {
      setShowSecondSection(true);
    }
  };

  const handleRedirectToScanPage = () => {
    if (!isRequestMode) {
      dispatch(closeDialog());
      dispatch(
        setReturnHeader({
          seller: selectedSeller,
          returnCode: returnCode,
        })
      );
      navigate(NavigationRoutes.RETURN_CREATE);
    } else {
      if (isConfirmed === false) {
        setIsConfirmed(true);
      } else {
        handleCreateReturn();
      }
    }
  };

  const checkIfDisabled = () => {
    if (!isRequestMode) {
      return !(selectedSeller.sellerId && returnCode !== 0);
    } else {
      return !(
        selectedSeller.sellerId &&
        returnCode !== 0 &&
        selectedRequests.length > 0
      );
    }
  };

  useEffect(() => {
    if (selectedRequests.length === 0) {
      setSelectedRequest({} as ReturnRequest);
      setShowSecondSection(false);
      setIsConfirmed(false);
    } else {
      /* if (modeStockVendable) {
        if (showSecondSection === false) setShowSecondSection(true);
        const mergedProducts = selectedRequests.reduce<Article__[]>(
          (accumulator, request) => {
            request.products.forEach((product) => {
              const existingProduct = accumulator.find(
                (p) => p.productId === product.productId
              );

              if (existingProduct) {
                if (
                  existingProduct.quantity !== undefined &&
                  product.quantity !== undefined
                ) {
                  const newQuantity =
                    existingProduct.quantity + product.quantity;
                  existingProduct.quantity = newQuantity;
                  existingProduct.initialQuantity = newQuantity;
                }
              } else {
                // Transform Article to Article__
                const newProduct: Article__ = {
                  ...product,
                  initialQuantity: product.quantity,
                };
                accumulator.push(newProduct);
              }
            });

            return accumulator;
          },
          []
        );

        setSellableProducts(mergedProducts);
      } */
    }
  }, [selectedRequests]);

  useEffect(() => {
    setSelectedSeller({} as Seller);
  }, [modeStockVendable]);

  useEffect(() => {
    setDialogWidth(showSecondSection ? "sm:max-w-[800px]" : "sm:max-w-[500px]");
  }, [showSecondSection]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (mainSectionRef.current) {
        setMainSectionHeight(mainSectionRef.current.offsetHeight);
      }
    });

    if (mainSectionRef.current) {
      resizeObserver.observe(mainSectionRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [selectedRequest]);

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent
        className={`${dialogWidth} transition-all duration-300 flex flex-col`}
        hasClose
      >
        <DialogHeader>
          <DialogTitle>
            <div className="flex justify-between">
              <p className="text-lg font-semibold">Crée une tournée</p>

              <div className="flex items-center gap-2">
                <Label className="font-normal">Par demande</Label>
                <Switch
                  checked={isRequestMode}
                  onCheckedChange={handleSwitchChange}
                />
              </div>
            </div>
          </DialogTitle>
        </DialogHeader>

        <div
          className={`flex gap-4 ${showSecondSection ? "h-[350px]" : "h-auto"}`}
        >
          <ScrollArea
            className={`flex-1 ${showSecondSection ? "overflow-y-auto" : ""}`}
            showScrollIndicator
          >
            <div className="flex flex-col gap-4">
              <ReturnCodeInput setReturnCode_={setReturnCode} />
              {isRequestMode && (
                <Tabs defaultValue="stock-non-vendable">
                  <TabsList className="flex gap-4">
                    <TabsTrigger
                      value="stock-non-vendable"
                      className="flex-1"
                      onClick={() => setModeStockVendable(false)}
                    >
                      Stock non vendable
                    </TabsTrigger>
                    <TabsTrigger
                      value="stock-vendable"
                      className="flex-1"
                      onClick={() => setModeStockVendable(true)}
                    >
                      Stock vendable
                    </TabsTrigger>
                  </TabsList>
                </Tabs>
              )}
              <div>
                <SellerSelect
                  isModeVendable={modeStockVendable}
                  isRequestMode={isRequestMode}
                  selectedSeller={selectedSeller}
                  setSelectedSeller={setSelectedSeller}
                />
              </div>
              {isRequestMode && selectedSeller.sellerId && (
                <div className="flex flex-col gap-4">
                  <div>
                    <RequestSelect
                      isModeVendable={modeStockVendable}
                      requestMode={isRequestMode}
                      sellerId={selectedSeller.sellerId}
                      selectedRequests={selectedRequests}
                      setSelectedRequests={setSelectedRequests}
                    />
                  </div>
                  {selectedRequests.length > 0 && (
                    <RequestList
                      /*       disabled={modeStockVendable} */
                      selectedRequests={selectedRequests}
                      handleRemoveRequest={handleRemoveRequest}
                      handleShowDetails={handleShowDetails}
                    />
                  )}
                </div>
              )}
              <div>
                <DropOffSelect
                  selectedDropOff={selectedDropOff}
                  setSelectedDropOff={setSelectedDropOff}
                />
              </div>
            </div>
          </ScrollArea>
          {showSecondSection && isRequestMode && (
            <ScrollArea className="flex-1 border border-gray-400 rounded-lg p-4">
              {selectedRequest?.products?.map((product) => (
                <Product key={product.genCode} product={product} />
              ))}
            </ScrollArea>
          )}
          {/*  {showSecondSection && isRequestMode && modeStockVendable === true && (
            <ScrollArea className="flex-1 border border-gray-400 rounded-lg p-4">
              {sellableProducts.map((product) => (
                <Product_
                  key={product.genCode}
                  product={product}
                  setValidProducts={setSellableProducts}
                />
              ))}
            </ScrollArea>
          )} */}
        </div>

        <DialogFooter className="flex flex-col sm:flex-row gap-2 mt-4 items-center justify-between sm:justify-between">
          <div className="flex items-center gap-2">
            {isConfirmed && (
              <p className="text-sm text-gray-500">
                Voulez-vous vraiment créer une tournée avec ces demandes?
              </p>
            )}
          </div>
          <div className="flex gap-2">
            <Button variant="outline" onClick={handleCancel}>
              Annuler
            </Button>
            {!isConfirmed ? (
              <DialogLoaderButton
                isLoading={isLoading}
                disabled={checkIfDisabled()}
                handleSubmit={handleRedirectToScanPage}
                name={isRequestMode ? "Confirmer" : "Suivant"}
              />
            ) : (
              <DialogLoaderButton
                isLoading={isLoading}
                disabled={checkIfDisabled() || isLoading}
                handleSubmit={handleRedirectToScanPage}
                name={"Créer"}
              />
            )}
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ReturnCreationDialog;
