import { toast } from "@/hooks/use-toast";
import { Article } from "@/interfaces/Product";
import { getProductDetails } from "@/services/product-details.service";
import { convertProduct } from "@/utils/convert-product";
import { checkExistance, productValidation } from "@/utils/product-scan";
import { LoaderCircle, ScanBarcode } from "lucide-react";
import React from "react";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { ScrollArea } from "../ui/scroll-area";
import AdjustableProduct from "../product/AdjustableProduct";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";

interface AdjustableProductScannerProps {
  validProducts: Article[];
  setValidProducts: React.Dispatch<React.SetStateAction<Article[]>>;
  disabled?: boolean;
  fixed?: boolean;
  forLitige?: boolean;
}

const AdjustableProductScanner: React.FC<AdjustableProductScannerProps> = ({
  validProducts,
  setValidProducts,
  disabled,
  fixed,
  forLitige,
}) => {
  const [barcode, setBarcode] = React.useState("");
  const [detailsLoading, setDetailsLoading] = React.useState(false);
  const { articles } = useSelector((state: RootState) => state.return.return);
  const handleBarcodeChange = (value: string) => {
    setBarcode(value.trim());
  };

  const handleScanButtonClick = () => {
    if (articles && articles.length > 0) {
      addConvertedProductToList_(barcode);
    } else {
      addConvertedProductToList(barcode);
    }
  };

  const addConvertedProductToList = async (barcode: string) => {
    if (barcode === "") {
      return;
    }
    const exists = checkExistance(barcode, validProducts);
    if (exists) {
      setDetailsLoading(false);
      toast({
        title: "Produit déjà scanné",
        variant: "alert",
      });
    } else {
      setDetailsLoading(true);
      const response = await getProductDetails(barcode);
      if (response.status === 200 && response.data.success === true) {
        const product = convertProduct(response.data.data);
        setDetailsLoading(false);
        setValidProducts([...validProducts, { ...product, quantity: 1 }]);
        toast({
          title: "Produit scanné",
          variant: "success",
        });
      } else {
        setDetailsLoading(false);
        toast({
          title: "Produit non trouvé",
          variant: "destructive",
        });
      }
    }
    resetInput();
  };

  const addConvertedProductToList_ = (barcode: string) => {
    console.log("addConvertedProductToList_");
    const valid = productValidation(articles, barcode, validProducts);

    if (valid === 1) {
      toast({
        title: "Produit déjà scanné",
        variant: "alert",
      });
      resetInput();
    } else if (valid === 2) {
      toast({
        title: "Produit non trouvé",
        variant: "destructive",
      });
      resetInput();
    } else if (valid !== 1 && valid !== 2) {
      if (typeof valid !== "number") {
        setValidProducts([...validProducts, valid]);
      }
      toast({
        title: "Produit scanné",
        variant: "success",
      });
      console.log("product added to list");

      resetInput();
    }
  };

  const resetInput = () => {
    setBarcode("");
  };

  return (
    <>
      <div className="px-4 flex items-center justify-between gap-2">
        <Input
          placeholder="Chercher un produit par code-barres"
          className="w-full h-10"
          value={barcode}
          onChange={(e) => handleBarcodeChange(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleScanButtonClick();
            }
          }}
          disabled={detailsLoading || disabled}
        />
        <Button
          variant="outline"
          className="h-10"
          disabled={detailsLoading || disabled}
          onClick={handleScanButtonClick}
        >
          {detailsLoading ? (
            <LoaderCircle className="h-6 w-6 animate-spin" />
          ) : (
            <ScanBarcode className="h-6 w-6" />
          )}
        </Button>
      </div>
      <ScrollArea className={`${fixed ? "min-h-[125px]" : "flex-grow"} px-2`}>
        <div className="px-2">
          {validProducts && validProducts.length > 0 ? (
            validProducts.map((product) => (
              <AdjustableProduct
                key={product.genCode}
                product={product}
                setValidProducts={setValidProducts}
                disabled={disabled}
                forLitige={forLitige}
              />
            ))
          ) : (
            <div className="flex items-center justify-center h-32">
              <p className="text-center text-gray-500">Aucun produit scanné</p>
            </div>
          )}
        </div>
      </ScrollArea>
    </>
  );
};

export default AdjustableProductScanner;
