import PickUpConfirmationDialog from "@/components/dialogs/PickUpConfirmationDialog";
import ActionButton from "@/components/loader-button/ActionButton";
import { NavigationRoutes } from "@/components/navigation/constants";
import ProductScanner from "@/components/product-scan/ProductScanner";
import { Toaster } from "@/components/ui/toaster";
import { PICKUP_CONFIRMATION_DIALOG } from "@/constants/constants";
import { toast } from "@/hooks/use-toast";
import { Article } from "@/interfaces/Product";
import { ReturnToCreate } from "@/interfaces/Return";
import { confirmerRamassage } from "@/services/return-delivery-confirmation.service";
import { closeDialog, openDialog } from "@/store/reducers/dialogReducer";
import { RootState } from "@/store/store";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ReturnPickUpValidationPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validProducts, setValidProducts] = React.useState<Article[]>([]);
  const [isLoading, setIsloading] = React.useState(false);
  const return_ = useSelector((state: RootState) => state.return.return);
  const isOpen = useSelector((state: RootState) => state.dialog.isOpen);

  console.log("pickup return", return_);
  const validationButtonDisabled = () => {
    if (return_.numTournee !== undefined) {
      return validProducts.length !== return_.articles.length;
    }
  };

  const handleValidation = async () => {
    if (isOpen === false) {
      dispatch(openDialog(PICKUP_CONFIRMATION_DIALOG));
      return;
    }
    const request = {
      numTournee: return_.numTournee.toString() || "",
      articles: validProducts,
      isSellerVIP: return_.isSellerVIP,
      sellerId: return_.sellerId,
      entityId: return_.entityId,
    } as ReturnToCreate;
    dispatch(closeDialog());
    setIsloading(true);
    const response = await confirmerRamassage(request);
    if (response.status === 200 && response.data.success === true) {
      toast({
        title: "Ramassage validée",
        variant: "success",
      });
      navigate(NavigationRoutes.DASHBOARD, { replace: true });
    } else {
      toast({
        title: "Erreur lors de la validation",
        variant: "destructive",
      });
    }
    setIsloading(false);
  };

  return (
    <div className="flex flex-col h-full gap-4">
      <div className="px-4 py-4 flex items-start justify-between gap-2 flex-row ">
        <p className="text-lg font-semibold">
          N° de retour: {return_.numTournee}
        </p>
        <p className="text-lg font-semibold">Vendeur: {return_.sellerName}</p>
      </div>
      <ProductScanner
        validProducts={validProducts}
        setValidProducts={setValidProducts}
        disabled={isLoading}
      />
      <div className="px-4 flex space-x-4 flex-row">
        <ActionButton
          loading={isLoading}
          disabled={validationButtonDisabled() || isLoading}
          handleClick={handleValidation}
          label="Valider le ramassage"
        />
      </div>
      <Toaster />
      <PickUpConfirmationDialog handleValidate_={handleValidation} />
    </div>
  );
};

export default ReturnPickUpValidationPage;
