import { IReturn } from "@/interfaces/Return";
import React from "react";
import ProductCounter from "../product-counter/ProductCounter";
import { formateDate } from "@/utils/date-utils";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PRIMARY_COLOR } from "@/constants/constants";
import { setReturn } from "@/store/reducers/returnReducer";
import { NavigationRoutes } from "../navigation/constants";
import DropOffTag from "../tags/DropoffTag";
import StatusTag from "../tags/StatusTag";
import SourceTag from "../tags/SourceTag";

interface CanceledReturnProps {
  return_: IReturn;
}

const CanceledReturn: React.FC<CanceledReturnProps> = ({ return_ }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleReturnClick_ = () => {
    dispatch(setReturn(return_));
    navigate(NavigationRoutes.RETURN_DETAILS);
  };

  return (
    <div
      className={`w-full flex flex-col rounded-lg shadow-md bg-white hover:bg-gray-50 my-2 border border-${PRIMARY_COLOR} border-2 p-3 space-y-2 cursor-pointer  transition-colors duration-200`}
      onClick={handleReturnClick_}
    >
      <div className="flex flex-col sm:flex-row sm:items-center justify-between w-full">
        <div className="flex items-center gap-2">
          <h2 className="text-lg font-semibold text-gray-800 mb-1 sm:mb-0">
            Tournee N° {return_.numTournee}
          </h2>
          <span className="text-md text-gray-500">({return_.numPOScale})</span>
        </div>

        <div className="flex flex-wrap items-center gap-2 mt-1 sm:mt-0">
          <SourceTag text={return_.sourceVendable} />
          <DropOffTag
            entityId={return_.entityId}
            entityName={return_.entityName}
          />
          <StatusTag
            text={return_.statutName}
            bakcgroundColor={return_.statutBackground}
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <h3 className="text-sm font-bold text-gray-700">
          {return_.sellerName}
        </h3>
        <p className="text-xs text-gray-500">
          {formateDate(return_.createdon)}
        </p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <ProductCounter productCount={return_.countArticles} />
      </div>
    </div>
  );
};

export default CanceledReturn;
