import { IReturn } from "@/interfaces/Return";
import React from "react";
import { Button } from "../ui/button";
import ProductCounter from "../product-counter/ProductCounter";
import { formateDate } from "@/utils/date-utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import {
  DELETE_CONFIRMATION_DIALOG,
  HUB_ROLE,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  SELLER_ROLE,
  WAREHOUSE_ROLE,
} from "@/constants/constants";
import { Download, Trash2 } from "lucide-react";
import { setReturn } from "@/store/reducers/returnReducer";
import { handleReturnClick } from "@/utils/return-navigation";
import { NavigationRoutes } from "../navigation/constants";
import { handleFileDownload } from "@/utils/file-download";
import { setReturnHeader } from "@/store/reducers/returnHeaderReducer";
import { openDialog } from "@/store/reducers/dialogReducer";
import DropOffTag from "../tags/DropoffTag";
import StatusTag from "../tags/StatusTag";
import SourceTag from "../tags/SourceTag";

interface ReturnProps {
  return_: IReturn;
}

const Return: React.FC<ReturnProps> = ({ return_ }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roles = useSelector((state: RootState) => state.authentication.roles);

  const onLitigeClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(setReturn(return_));
    navigate(NavigationRoutes.RETURN_LITIGE);
  };

  const onDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      setReturnHeader({
        returnCode: return_.numTournee,
        seller: {
          sellerId: return_.sellerId,
          sellerName: return_.sellerName,
          isSellerVIP: return_.isSellerVIP,
        },
      })
    );
    dispatch(openDialog(DELETE_CONFIRMATION_DIALOG));
  };

  const onDownloadClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (return_.url)
      handleFileDownload(return_.url, return_.numTournee.toString() || "");
  };

  const renderLitigeButton = () => {
    if (
      (return_.statutId === 4 && roles.includes(SELLER_ROLE)) ||
      (return_.statutId === 7 && roles.includes(HUB_ROLE))
    ) {
      return (
        <Button
          className="flex items-center justify-center space-x-1 border rounded-md px-2 py-1 border-red-500 hover:bg-red-100 text-red-500"
          variant="outline"
          onClick={onLitigeClick}
        >
          <span className="text-xs font-semibold">Litige</span>
        </Button>
      );
    }
    return null;
  };

  const renderDeleteButton = () => {
    if (return_.statutId === 2 && roles.includes(WAREHOUSE_ROLE)) {
      return (
        <Button
          className="flex items-center justify-center w-8 h-8 border rounded-md bg-white hover:bg-red-50 border-red-500"
          onClick={onDeleteClick}
        >
          <Trash2 className="w-4 h-4 text-red-500" />
        </Button>
      );
    }
    return null;
  };

  const renderDownloadButton = () => {
    if (return_.url && roles.includes(WAREHOUSE_ROLE)) {
      return (
        <Button
          className="flex items-center justify-center w-8 h-8 border rounded-md bg-white hover:bg-teal-50"
          style={{ borderColor: SECONDARY_COLOR }}
          onClick={onDownloadClick}
        >
          <Download className="w-4 h-4" style={{ color: PRIMARY_COLOR }} />
        </Button>
      );
    }
    return null;
  };

  const handleReturnClick_ = () => {
    dispatch(setReturn(return_));
    handleReturnClick(return_, roles, navigate);
  };

  return (
    <div
      className={`w-full flex flex-col rounded-lg shadow-md bg-white hover:bg-gray-50 my-2 border border-${PRIMARY_COLOR} border-2 p-3 space-y-2 cursor-pointer  transition-colors duration-200`}
      onClick={handleReturnClick_}
    >
      <div className="flex flex-col sm:flex-row sm:items-center justify-between w-full">
        <h2 className="text-lg font-semibold text-gray-800 mb-1 sm:mb-0">
          Tournee N° {return_.numTournee}
        </h2>
        <div className="flex flex-wrap items-center gap-2 mt-1 sm:mt-0">
          <SourceTag text={return_.sourceVendable} />
          <DropOffTag
            entityId={return_.entityId}
            entityName={return_.entityName}
          />
          <StatusTag
            text={return_.statutName}
            bakcgroundColor={return_.statutBackground}
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <h3 className="text-sm font-bold text-gray-700">
          {return_.sellerName}
        </h3>
        <p className="text-xs text-gray-500">
          {formateDate(return_.createdon)}
        </p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <ProductCounter productCount={return_.countArticles} />
        <div className="flex items-center space-x-2">
          {renderDownloadButton()}
          {renderDeleteButton()}
          {renderLitigeButton()}
        </div>
      </div>
    </div>
  );
};

export default Return;
